(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/visibility-wrapper/assets/javascripts/visibility-wrapper.js') >= 0) return;  svs.modules.push('/components/lb-ui/visibility-wrapper/assets/javascripts/visibility-wrapper.js');
"use strict";


const {
  useEffect,
  useRef
} = React;
const {
  useMediaQuery,
  features
} = svs.components.lbUtils.useMediaQuery;
const {
  clx
} = svs.components.lbUtils;
const VISIBILITY_TRANSITION_DURATION = 500;

const VisibilityWrapper = _ref => {
  let {
    ariaLabel,
    children,
    className,
    HeaderComponent,
    isVisible,
    style,
    useOpacityTransition = false
  } = _ref;
  const contentRef = useRef();
  const wrapperRef = useRef();
  const isInitialRender = useRef(true);
  const shouldReduceMotion = useMediaQuery(features.PREFERS_REDUCED_MOTION);
  const setVisibility = visible => {
    if (contentRef.current) {
      contentRef.current.style.visibility = visible ? '' : 'hidden';
    }
  };
  useEffect(() => {
    const handleVisibilityChange = () => {
      setVisibility(isVisible);
    };
    let timeoutId;
    if (!shouldReduceMotion && !isInitialRender.current && !isVisible) {
      timeoutId = setTimeout(handleVisibilityChange, VISIBILITY_TRANSITION_DURATION);
    } else {
      handleVisibilityChange();
    }
    if (isInitialRender.current) {
      isInitialRender.current = false;
    }
    return () => clearTimeout(timeoutId);
  }, [isVisible, shouldReduceMotion]);
  const blockName = 'visibility_wrapper';
  const classNames = clx(blockName, {
    ["".concat(blockName, "--reduced-motion")]: shouldReduceMotion,
    ["".concat(blockName, "--with-header")]: HeaderComponent,
    ["".concat(blockName, "--visible")]: isVisible,
    ["".concat(blockName, "--opacity-transition")]: useOpacityTransition
  }, className);
  return React.createElement("div", {
    "aria-label": ariaLabel,
    className: classNames,
    ref: wrapperRef,
    style: style
  }, HeaderComponent && React.createElement("div", {
    className: "visibility_wrapper__header"
  }, React.createElement(HeaderComponent, null)), React.createElement("div", {
    className: "visibility_wrapper__content",
    ref: contentRef
  }, children));
};
setGlobal('svs.components.lbUi.visibilityWrapper', {
  VisibilityWrapper
});

 })(window);